<template>
  <v-dialog v-model="isShown" width="500">
    <v-card>
      <v-card-title>
        {{ $t('business.members.remove_member') }}
      </v-card-title>
      <v-card-text>
        {{
          $t('business.members.remove_member_prompt', {
            organisation,
          })
        }}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn v-if="!loading" x-large color="error" outlined @click="isShown = false">
          {{ $t('global.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn x-large color="error" :disabled="loading" :loading="loading" @click="$emit('removeMember')">
          {{ $t('business.members.remove_member') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  props: {
    value: Boolean,
    loading: Boolean,
  },
  computed: {
    organisation(): string {
      return (this.$store.state as RootState).business?.company.name ?? ''
    },
    isShown: {
      get(): boolean {
        return this.value
      },
      set(newValue: boolean): void {
        this.$emit('input', newValue)
      },
    },
  },
})
</script>

<style></style>
